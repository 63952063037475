import React from 'react'
import { connect } from 'lape'
import capitalize from 'lodash/capitalize'
import {
  ActionButton,
  Avatar,
  Cell,
  Item,
  MoreBar,
  TableWidget,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'

import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { PageHeader, PageHeaderRouterAction } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import Form from '@src/features/Form/Form'
import {
  documentsBulkRequestFormRequests,
  getDocumentsBulkRequestEligibleEmployeesTableRequests,
} from '@src/api/documents'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  DocumentsBulkRequestEligibleEmployeeInterface,
  DocumentsBulkRequestEligibleEmployeesStatsInterface,
  DocumentsBulkRequestInterface,
} from '@src/interfaces/documents'
import ActionWidget from '@components/ActionWidget/ActionWidget'

import {
  getDocumentsBulkRequestStatusColor,
  isReadyToSubmit,
  useRefreshRequestStatus,
} from './common'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  documentsBulkRequestEligibleEmployeeCountryColumn,
  documentsBulkRequestEligibleEmployeeDepartmentColumn,
  documentsBulkRequestEligibleEmployeeNameColumn,
  documentsBulkRequestEligibleEmployeeSeniorityColumn,
  documentsBulkRequestEmployeeValidationStatusColumn,
} from '@src/constants/columns/documentsBulkRequestEligibleEmployees'
import { useTable } from '@components/Table/hooks'

const ROW = {
  cells: [
    {
      ...documentsBulkRequestEligibleEmployeeNameColumn,
      width: 200,
    },
    {
      ...documentsBulkRequestEligibleEmployeeCountryColumn,
      width: 200,
    },
    {
      ...documentsBulkRequestEligibleEmployeeDepartmentColumn,
      width: 200,
    },
    {
      ...documentsBulkRequestEligibleEmployeeSeniorityColumn,
      width: 180,
    },
    {
      ...documentsBulkRequestEmployeeValidationStatusColumn,
      width: 200,
    },
  ].filter(Boolean),
}

const Preview = connect(() => {
  const { values } = useLapeContext<DocumentsBulkRequestInterface>()

  const canSubmit = isReadyToSubmit(values.status)

  const ReviewAndSubmitButton = (
    <MoreBar.Action
      disabled={!canSubmit}
      useIcon="ArrowThinRight"
      use={InternalLink}
      to={pathToUrl(ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.REVIEW, {
        id: values.id,
      })}
    >
      Review and submit
    </MoreBar.Action>
  )

  useRefreshRequestStatus()

  const table = useTable<
    DocumentsBulkRequestEligibleEmployeeInterface,
    DocumentsBulkRequestEligibleEmployeesStatsInterface
  >(
    getDocumentsBulkRequestEligibleEmployeesTableRequests(values.id),
    undefined,
    undefined,
    { disable: !values.id },
  )

  const attachedDrafts = values.document_drafts || []
  const attachedTemplates = values.document_templates || []

  return (
    <>
      <PageHeader
        title={values.name}
        subtitle={
          <Text color={getDocumentsBulkRequestStatusColor(values.status)}>
            {capitalize(values.status.name)}
          </Text>
        }
        backUrl={ROUTES.APPS.DOCUMENTS.BULK_REQUEST.LIST}
        routerAction={PageHeaderRouterAction.navigate}
      />
      <PageBody>
        <VStack space="s-24">
          {values.status.id === 'validation_pending' && (
            <ActionWidget
              title="Request is created and waiting for validation"
              text="Validation process can take a while.
            When it is finished, you will be able to proceed to the Review step and submit the request."
              avatar={
                <Avatar
                  progress={0.6}
                  useIcon="16/SandWatch"
                  color={Token.color.orange}
                  progressColor={Token.color.orange}
                />
              }
            >
              <MoreBar>{ReviewAndSubmitButton}</MoreBar>
            </ActionWidget>
          )}
          {values.status.id === 'validation_in_progress' && (
            <ActionWidget
              title="Eligible employees are being validated for this request"
              text='Validation process can take a while.
            When it is finished, you will be able to proceed to the "Review" step and submit the request.'
              avatar={
                <Avatar
                  progress={0.6}
                  useIcon="16/SandWatch"
                  color={Token.color.orange}
                  progressColor={Token.color.orange}
                />
              }
            >
              <MoreBar>{ReviewAndSubmitButton}</MoreBar>
            </ActionWidget>
          )}
          {canSubmit && (
            <ActionWidget
              title="Request validation is completed"
              text="You can review eligible employees and submit the request"
              avatar={<Avatar useIcon="Check" color={Token.color.blue} />}
            >
              <MoreBar>{ReviewAndSubmitButton}</MoreBar>
            </ActionWidget>
          )}
          {values.status.id === 'submit_in_progress' && (
            <ActionWidget
              title="Request is waiting to complete"
              avatar={
                <Avatar useIcon="16/SandWatch" progress={0.6} color={Token.color.blue} />
              }
            />
          )}
          <Cell p={0}>
            <VStack width="100%">
              {attachedDrafts.map(({ id, name }) => (
                <Item key={id}>
                  <Item.Avatar>
                    <Avatar useIcon="Document" />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>{name}</Item.Title>
                    <Item.Description>Document request</Item.Description>
                  </Item.Content>
                </Item>
              ))}
              {attachedTemplates.map(({ id, name, template_type }) => (
                <Item key={id}>
                  <Item.Avatar>
                    <Avatar useIcon="Document" />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>{name}</Item.Title>
                    <Item.Description>
                      {template_type?.id === 'esignature'
                        ? 'E-signature document template'
                        : 'Document template'}
                    </Item.Description>
                  </Item.Content>
                  <Item.Side>
                    <ActionButton
                      useIcon="LinkExternal"
                      use={InternalLink}
                      target="_blank"
                      to={pathToUrl(ROUTES.FORMS.DOCUMENT_TEMPLATE.PREVIEW, { id })}
                    >
                      Open
                    </ActionButton>
                  </Item.Side>
                </Item>
              ))}
            </VStack>
          </Cell>
          <TableWidget>
            <TableWidget.Table>
              <AdjustableTable<
                DocumentsBulkRequestEligibleEmployeeInterface,
                DocumentsBulkRequestEligibleEmployeesStatsInterface
              >
                useWindowScroll
                name={TableNames.BulkRequestDocumentsEligibleEmployees}
                row={ROW}
                {...table}
                noDataMessage="All eligible employees will appear here"
                dataType="Eligible employee"
              />
            </TableWidget.Table>
          </TableWidget>
        </VStack>
      </PageBody>
    </>
  )
})

export const PreviewDocumentsBulkRequest = () => (
  <Form api={documentsBulkRequestFormRequests}>
    <Preview />
  </Form>
)
