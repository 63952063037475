import React from 'react'
import { connect } from 'lape'
import { Flex, VStack, Widget } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { PageActions } from '@components/Page/PageActions'
import Filters from '@src/pages/Forms/EmployeePerformance/components/Filters'
import { useParams } from 'react-router-dom'
import { SkillsCardView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Cards/SkillsCardView'
import { CultureValuesCardView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Cards/CultureValuesCardView'
import EditButton from '@src/pages/Forms/EmployeePerformanceViewLayout/EditButton'
import { ProbationDeliverablesCardView } from '@src/pages/Forms/ProbationViewLayout/Cards/ProbationDeliverablesCardView'
import { getRecommendations } from '@src/pages/Forms/EmployeePerformanceViewLayout/ScorecardContentView'
import { BarRaiserView } from '@src/pages/Forms/EmployeePerformanceViewLayout/BarRaiserView'
import { FeedbackView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Sections/FeedbackView'

export const ProbationViewContent = connect(() => {
  const params = useParams<{ id: string }>()
  const { values } = useLapeContext<ReviewSummaryInterface>()
  const cycleId = params.id

  if (!values.summary) {
    return null
  }

  const recommendations = getRecommendations(values)

  return (
    <VStack space="s-16">
      <Widget p="s-16" pb={0}>
        <Filters
          cycleId={cycleId}
          onFilterChange={() => {
            // TODO: check if should be removed
          }}
        />
      </Widget>
      <ProbationDeliverablesCardView />
      <SkillsCardView />
      <CultureValuesCardView />
      {!!recommendations.length && <BarRaiserView recommendations={recommendations} />}
      <FeedbackView
        recommendationData={recommendations?.[0]?.recommendation}
        reviews={recommendations?.map(el => el.recommendation)}
      />
      <PageActions maxWidth="100%">
        <Flex justifyContent="center">
          <EditButton />
        </Flex>
      </PageActions>
    </VStack>
  )
})
