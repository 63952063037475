import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { IdAndName } from '@src/interfaces'
import { selectorKeys } from '@src/constants/api'
import { RevolutTheme } from '@src/styles/theme'
import { FinalGrade, FinalGradeInterface } from '@src/interfaces/performance'
import { getColor } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'

export interface GradesMapInterface {
  [key: string]: string
}
export const useGetReviewGradesMap = () => {
  const { options: gradesOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.grades,
  )
  const gradesMap: GradesMapInterface = {}
  gradesOptions.forEach(option => {
    gradesMap[option.value.id] = option.value.name
  })

  return gradesMap
}

export const getColorsAndGrade = (
  theme: RevolutTheme,
  grade?: FinalGradeInterface | null,
) => {
  switch (grade?.id) {
    case FinalGrade.Poor:
      return {
        color: getColor(theme, Color.BACKGROUND),
        background: getColor(theme, Color.RED),
        grade: grade.label,
      }
    case FinalGrade.AverageMinus:
      return {
        color: getColor(theme, Color.FOREGROUND),
        background: getColor(theme, Color.GREY_50_OPAQUE_10),
        grade: grade.label,
      }
    case FinalGrade.AveragePlus:
      return {
        color: getColor(theme, Color.BACKGROUND),
        background: getColor(theme, Color.TEAL),
        grade: grade.label,
      }
    case FinalGrade.Strong:
      return {
        color: getColor(theme, Color.BACKGROUND),
        background: getColor(theme, Color.TEAL),
        grade: grade.label,
      }
    case FinalGrade.Exceptional:
      return {
        color: getColor(theme, Color.BACKGROUND),
        background: getColor(theme, Color.TEAL),
        grade: grade.label,
      }
    default:
      return null
  }
}

export const getInverseColorsAndGrade = (
  theme: RevolutTheme,
  grade?: FinalGradeInterface | null,
) => {
  switch (grade?.id) {
    case FinalGrade.Poor:
      return {
        color: getColor(theme, Color.RED),
        background: 'transparent',
        grade: grade.label,
      }
    case FinalGrade.AverageMinus:
      return {
        color: getColor(theme, Color.FOREGROUND),
        background: 'transparent',
        grade: grade.label,
      }
    case FinalGrade.AveragePlus:
      return {
        color: getColor(theme, Color.FOREGROUND),
        background: 'transparent',
        grade: grade.label,
      }
    case FinalGrade.Strong:
      return {
        color: getColor(theme, Color.GREEN),
        background: 'transparent',
        grade: grade.label,
      }
    case FinalGrade.Exceptional:
      return {
        color: getColor(theme, Color.GREEN),
        background: 'transparent',
        grade: grade.label,
      }
    default:
      return null
  }
}
