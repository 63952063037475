import React, { useState } from 'react'
import {
  ChangeScorecardInterface,
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewScorecardInterface,
  ScorecardError,
  SkillCardInterface,
  SummarySkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { FormError } from '@src/features/Form/LapeForm'
import {
  CardContentTypes,
  cardHasMissingValues,
  countSuperpowers,
  SelectedFieldInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import ScorecardValueBasedSection from '@components/ScorecardGeneral/ScorecardValueBasedSection'
import { ScorecardSectionRevamped } from '@components/ScorecardGeneral/ScorecardSectionRevamped'

interface Props {
  skillsCards: ValueBasedCardInterface[] | SummarySkillCardInterface[]
  selectedField: SelectedFieldInterface
  isViewMode?: boolean
  hasManagerValues?: boolean
  errors?: FormError<ReviewScorecardInterface>
  onChange?: (params: ChangeScorecardInterface) => void
  onChangeManagerValues?: (params: ChangeScorecardInterface) => void
  errorsList?: ScorecardError[] | null
  touched?: boolean
  justificationOnly?: boolean
}

export const SkillsCommon = connect(
  ({
    skillsCards,
    isViewMode = false,
    selectedField,
    hasManagerValues,
    onChange,
    onChangeManagerValues,
    touched,
    errorsList,
    justificationOnly = false,
  }: Props) => {
    const [resultsLoading, setResultsLoading] = useState<(number | string)[]>([])

    if (selectedField.cardIndex === undefined) {
      return null
    }
    const card = skillsCards[selectedField.cardIndex]

    if (!card) {
      return null
    }

    const missingValues = cardHasMissingValues(
      CardContentTypes.SKILLS,
      card as SkillCardInterface,
    )

    return hasManagerValues ? (
      <ScorecardValueBasedSection
        mt={0}
        card={card as ValueBasedCardInterface}
        cardIndex={selectedField.cardIndex}
        category={ReviewCategory.Performance}
        errors={errorsList?.[selectedField.cardIndex]}
        key={`${(card as ValueBasedCardInterface).value_id}`}
        onChange={onChangeManagerValues}
        sectionErrors={errorsList}
        superpowersCount={countSuperpowers(skillsCards as ValueBasedCardInterface[])}
        touched={touched}
        type={PerformanceReviewTypes.managerValuesSkills}
        valueId={(card as ValueBasedCardInterface).value_id}
        viewMode={isViewMode}
        hideCount
      />
    ) : (
      <ScorecardSectionRevamped
        skillId={`${
          (card as SkillCardInterface).skill_id ||
          (card as SkillCardInterface).criteria_key
        }`}
        cardIndex={selectedField.cardIndex}
        key={`${
          (card as SkillCardInterface).skill_id ||
          (card as SkillCardInterface).criteria_key
        }`}
        disabled={isViewMode}
        type={PerformanceReviewTypes.skills}
        category={ReviewCategory.Performance}
        onChange={onChange}
        viewMode={isViewMode}
        errors={errorsList?.[selectedField.cardIndex]}
        sectionErrors={errorsList}
        touched={!!errorsList?.[selectedField.cardIndex] || touched}
        previousReviews={undefined}
        card={card as SkillCardInterface}
        resultLoading={
          !!(card as SkillCardInterface).skill_id &&
          resultsLoading.includes((card as SkillCardInterface).skill_id!)
        }
        onSetResultLoading={setResultsLoading}
        showErrorLabel={missingValues}
        hideResult
        justificationOnly={justificationOnly}
      />
    )
  },
)
