import React from 'react'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import { Target } from '@revolut/icons'
import {
  CardContentTypes,
  getFinalGradeTitleFromRating,
  getKpiGrades,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { KPI } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/KPI'
import { Deliverables } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'
import { KPIsCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/KPIsCardTable'
import { DeliverablesCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/DeliverablesCardTable'

export const DeliverablesCardView = connect(() => {
  const { values } = useLapeContext<ReviewSummaryInterface>()
  const hasKPI = !!values.summary?.kpis_section
  const gradesMap = values.grade_label_mapping

  const deliverablesGrades = getKpiGrades(gradesMap, false)
  const kpiGrades = getKpiGrades(gradesMap, true)

  return (
    <>
      {hasKPI ? (
        <Card
          data={values}
          renderExpandedContent={() => (
            <KPI isViewMode reviewData={values.summary} kpiGrades={kpiGrades} />
          )}
          additionalInfo={<KPIsCardTable gradesMap={gradesMap} />}
          type={CardContentTypes.KPI}
          title="Deliverables"
          icon={Target}
          fields={[
            {
              field: 'summary.kpis_section.rating',
              title: 'Contribution and impact',
              grades: kpiGrades,
              gradeRecommendation: null,
            },
          ]}
          finalRating={getFinalGradeTitleFromRating(
            gradesMap,
            values.summary?.kpis_section?.rating,
          )}
          isViewMode
        />
      ) : (
        <Card
          data={values}
          renderExpandedContent={() => (
            <Deliverables
              isViewMode
              reviewData={values.summary}
              deliverablesGrades={deliverablesGrades}
            />
          )}
          additionalInfo={<DeliverablesCardTable gradesMap={gradesMap} />}
          type={CardContentTypes.DELIVERABLES}
          title="Deliverables"
          icon={Target}
          fields={[
            {
              field: `summary.deliverables.rating`,
              title: 'Contribution and impact',
              grades: deliverablesGrades,
              gradeRecommendation: null,
            },
          ]}
          justification={values?.summary?.deliverables?.skipped_section_justifications}
          finalRating={getFinalGradeTitleFromRating(
            gradesMap,
            values.summary?.deliverables?.rating,
          )}
          isViewMode
        />
      )}
    </>
  )
})
