import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Text,
  Box,
  chain,
  Flex,
  HStack,
  VStack,
  IconButton,
  Token,
  Cell,
  BREAKPOINTS,
  Item,
  Group,
  Avatar,
  Button,
  TextSkeleton,
} from '@revolut/ui-kit'

import { PageBody } from '@components/Page/PageBody'
import SideBar from '@components/SideBar/SideBar'
import { documentsBulkRequestTriggerValidation } from '@src/api/documents'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { TemplatesEditor } from './TemplatesEditor'
import { CommonTabProps } from '../../common'

export const Templates = ({ bulkRequestData, nextTabPath }: CommonTabProps) => {
  const params = useParams()

  const [currentTemplateIdx, setCurrentTemplateIdx] = useState(0)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const templates = bulkRequestData?.document_templates || []
  const currentTemplate = templates[currentTemplateIdx]

  useEffect(() => {
    if (!!bulkRequestData?.id && !templates.length) {
      navigateReplace(pathToUrl(nextTabPath, params))
    }
  }, [bulkRequestData?.id, templates.length])

  return (
    <PageBody maxWidth={BREAKPOINTS.xl}>
      <VStack>
        <Cell>
          <Flex flex={1} justifyContent="space-between" alignItems="center">
            {templates.length ? (
              <Box>
                {chain(
                  <Text variant="h6">{currentTemplate?.name}</Text>,
                  <Text variant="h6" color={Token.color.greyTone50}>
                    {currentTemplateIdx + 1}/{templates.length}
                  </Text>,
                )}
              </Box>
            ) : (
              <TextSkeleton width="s-64" />
            )}
            <HStack space="s-12">
              <IconButton
                size={20}
                color={Token.color.greyTone50}
                useIcon="ChevronLeft"
                onClick={() => {
                  if (currentTemplateIdx > 0) {
                    setCurrentTemplateIdx(currentTemplateIdx - 1)
                  } else {
                    setCurrentTemplateIdx(templates.length - 1)
                  }
                }}
              />
              <IconButton
                size={20}
                color={Token.color.greyTone50}
                useIcon="ChevronRight"
                onClick={() => {
                  if (currentTemplateIdx + 1 < templates.length) {
                    setCurrentTemplateIdx(currentTemplateIdx + 1)
                  } else {
                    setCurrentTemplateIdx(0)
                  }
                }}
              />
              <IconButton
                size={20}
                color={Token.color.greyTone50}
                useIcon="ViewListSmall"
                onClick={() => setIsSidebarOpen(true)}
              />
            </HStack>
          </Flex>
        </Cell>
        <Flex flexDirection="column" minHeight={500}>
          {currentTemplate && (
            <TemplatesEditor
              templateId={currentTemplate?.id}
              renderNextTabButton={(onSaveTemplate, handleErrors) => {
                return (
                  <Button
                    elevated
                    disabled={!bulkRequestData?.id}
                    onClick={async () => {
                      try {
                        await onSaveTemplate()
                      } catch (e) {
                        handleErrors(e)
                        return
                      }
                      await documentsBulkRequestTriggerValidation(bulkRequestData!.id)
                      navigateReplace(pathToUrl(nextTabPath, { id: bulkRequestData!.id }))
                    }}
                  >
                    Next
                  </Button>
                )
              }}
            />
          )}
        </Flex>
      </VStack>
      <SideBar
        title="Document templates"
        isOpen={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
      >
        <Group>
          {templates.map((template, idx) => (
            <Item
              key={template.id}
              use="label"
              onClick={() => setCurrentTemplateIdx(idx)}
              aria-pressed={template.id === templates[currentTemplateIdx].id}
            >
              <Item.Avatar>
                <Avatar useIcon="Document" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{template.name}</Item.Title>
              </Item.Content>
            </Item>
          ))}
        </Group>
      </SideBar>
    </PageBody>
  )
}
