import React from 'react'
import { connect } from 'lape'
import { Flex, VStack, Widget } from '@revolut/ui-kit'
import { DeliverablesCardView } from './Cards/DeliverablesCardView'
import { SkillsCardView } from './Cards/SkillsCardView'
import { CultureValuesCardView } from './Cards/CultureValuesCardView'
import { BarRaiserView } from './BarRaiserView'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { RecommendationTypes } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { AdditionalQuestions } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/AdditionalQuestions'
import Filters from '@src/pages/Forms/EmployeePerformance/components/Filters'
import { useParams } from 'react-router-dom'
import { PageActions } from '@components/Page/PageActions'
import EditButton from '@src/pages/Forms/EmployeePerformanceViewLayout/EditButton'
import { FeedbackView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Sections/FeedbackView'

export const getRecommendations = (values: ReviewSummaryInterface) => {
  const lmRecommendation = values.summary?.line_manager_extra_section
  const fmRecommendation = values.summary?.functional_manager_extra_section
  const peerRecommendations = values.summary?.peer_extra_sections
  const probationPipRecommendation = values.summary?.recommendation_extra_sections
  const obsoletePipRecommendation = values.summary?.pip_extra_sections

  const recommendations = []

  if (lmRecommendation) {
    recommendations.push({
      recommendation: lmRecommendation,
      type: RecommendationTypes.LM,
    })
  }
  if (fmRecommendation) {
    recommendations.push({
      recommendation: fmRecommendation,
      type: RecommendationTypes.FM,
    })
  }
  if (peerRecommendations?.length) {
    peerRecommendations.forEach(peerRecommendation => {
      recommendations.push({
        recommendation: peerRecommendation,
        type: RecommendationTypes.PEER,
      })
    })
  }
  if (probationPipRecommendation?.length) {
    probationPipRecommendation.forEach(peerRecommendation => {
      recommendations.push({
        recommendation: peerRecommendation,
        type: RecommendationTypes.PIP,
      })
    })
  }
  if (obsoletePipRecommendation?.length) {
    obsoletePipRecommendation.forEach(peerRecommendation => {
      recommendations.push({
        recommendation: peerRecommendation,
        type: RecommendationTypes.PIP_V1,
      })
    })
  }

  return recommendations
}

export const ScorecardContentView = connect(() => {
  const params = useParams<{ id: string }>()
  const { values } = useLapeContext<ReviewSummaryInterface>()
  const cycleId = params.id

  if (!values.summary) {
    return null
  }

  const recommendations = getRecommendations(values)

  return (
    <VStack space="s-16">
      <Widget p="s-16" pb={0}>
        <Filters cycleId={cycleId} onFilterChange={() => {}} />
      </Widget>
      <DeliverablesCardView />
      <SkillsCardView />
      <CultureValuesCardView />
      {!!recommendations.length && <BarRaiserView recommendations={recommendations} />}
      <FeedbackView
        recommendationData={recommendations?.[0]?.recommendation}
        reviews={recommendations?.map(el => el.recommendation)}
      />
      <AdditionalQuestions data={values.summary} isViewMode />
      <PageActions maxWidth="100%">
        <Flex justifyContent="center">
          <EditButton />
        </Flex>
      </PageActions>
    </VStack>
  )
})
