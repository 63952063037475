import React, { useEffect, useState } from 'react'
import { FinalGrade, FinalGradeInterface } from '@src/interfaces/performance'
import { RadioOptionInterface } from '@components/Inputs/RadioButtons/RadioButtons'
import { Box, Color, Flex, RadioSelect, Text } from '@revolut/ui-kit'
import { Message, ChevronDown, ChevronUp } from '@revolut/icons'
import { getInverseColorsAndGrade } from '@src/utils/grades'
import { useTheme } from '@src/styles/theme'
import Tooltip from '@components/Tooltip/Tooltip'

export interface GradeSelectProps {
  value?: FinalGradeInterface | null
  grades: RadioOptionInterface[]
  onChange: (grade?: FinalGradeInterface) => void
  comment?: string | null
  readOnly?: boolean
}

type ShevronProps = {
  open: boolean
}
const Shevron = ({ open }: ShevronProps) => {
  return open ? <ChevronUp size={16} /> : <ChevronDown size={16} />
}

const GradeSelect = ({
  grades,
  value,
  onChange,
  comment,
  readOnly,
}: GradeSelectProps) => {
  const theme = useTheme()
  const [selectedGrade, setSelectedGrade] = useState(value?.id)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setSelectedGrade(value?.id)
  }, [value])

  const options = grades.map(item => ({
    key: item.id,
    value: item,
    label: item.name as string,
  }))

  const labelText =
    value?.id === FinalGrade.Empty
      ? 'Select'
      : options.find(item => item.key === value?.id)?.label

  return (
    <Box width="100%">
      <Flex
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => !readOnly && setOpen(!open)}
        color={value ? Color.FOREGROUND : Color.GREY_20_OPAQUE_90}
      >
        <Flex alignItems="center">
          <Text color={getInverseColorsAndGrade(theme, value)?.color}>{labelText}</Text>
          {comment && (
            <Tooltip placement="top" text={comment || ''} hide={!comment} useFormatting>
              <Box style={{ flexShrink: 1 }} p="s-8">
                <Message size={12} color={Color.BLUE} />
              </Box>
            </Tooltip>
          )}
        </Flex>
        {!readOnly && <Shevron open={open} />}
      </Flex>
      <RadioSelect<RadioOptionInterface>
        open={open}
        onClose={() => setOpen(false)}
        options={options}
        value={grades.find(grade => grade.id === selectedGrade)}
        onChange={option => {
          const grade = (option?.id as FinalGrade) || FinalGrade.Empty
          setSelectedGrade(grade)
          onChange({
            id: option?.id,
            label: String(option?.name),
          })
        }}
        autoClose
        labelList="Grades"
      />
    </Box>
  )
}

export default GradeSelect
