import React from 'react'
import { Color, Text } from '@revolut/ui-kit'
import { FinalGradeInterface } from '@src/interfaces/performance'
import { useTheme } from '@src/styles/theme'
import { getColorsAndGrade, getInverseColorsAndGrade } from '@src/utils/grades'

type Props = {
  grade: FinalGradeInterface | null
  inverse?: boolean
  className?: string
  withPending?: boolean
}

export const PerformanceGradeRevamped = ({
  grade,
  inverse,
  className,
  withPending,
}: Props) => {
  const theme = useTheme()
  const data = inverse
    ? getInverseColorsAndGrade(theme, grade)
    : getColorsAndGrade(theme, grade)

  if (!data) {
    if (withPending) {
      return (
        <Text className={className} color={Color.GREY_TONE_20}>
          Pending
        </Text>
      )
    }

    return null
  }

  return (
    <Text className={className} backgroundColor={data.background} color={data.color}>
      {grade?.label}
    </Text>
  )
}
