import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys, selectorToUrl } from '../api'
import {
  BudgetInterface,
  BudgetTypes,
  CompanyBudgetInterface,
} from '@src/interfaces/budgets'
import {
  Colored,
  ColoredPercent,
} from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import React from 'react'
import styled from 'styled-components'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import capitalize from 'lodash/capitalize'
import { percentToColor } from '@src/features/Scorecard/constants'
import { formatMoney } from '@src/utils/format'
import { RevolutersStatuses } from '@src/interfaces/revoluters'
import { ROUTES } from '../routes'
import EmployeePerformanceChart from '@components/Charts/EmployeePerformanceChart/EmployeePerformanceChart'
import { getEmployeePerformanceGraph } from '@src/api/employees'
import Icon from '@components/Icon/Icon'
import { PercentageWrapper } from '@components/CommonSC/TableStatistics'
import { Color, Flex, Text, Token } from '@revolut/ui-kit'
import { getColor } from '@src/styles/colors'
import { Link } from 'react-router-dom'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { RevolutTheme } from '@src/styles/theme'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { getInverseColorsAndGrade } from '@src/utils/grades'

export const TooltipContainer = styled.div`
  padding: 12px;
  min-width: 180px;
  color: ${Token.color.background};
`

const CustomLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  justify-self: start;

  &:hover {
    text-decoration: underline;
  }
`

const getEntityNameColor = (name: string, theme: RevolutTheme) => {
  switch (name) {
    case RevolutersStatuses.opened:
      return getColor(theme, Color.GREY_20_OPAQUE_90)
    case RevolutersStatuses.approved:
      return theme.colors.green
    case RevolutersStatuses.pending:
      return theme.colors.warning
    case RevolutersStatuses.rejected:
      return theme.colors.red
    default:
      return theme.colors.foreground
  }
}

export const companyBudgetDepartmentNameColumn: ColumnInterface<CompanyBudgetInterface> =
  {
    type: CellTypes.text,
    idPoint: 'entity_id',
    dataPoint: 'entity_name',
    sortKey: 'department__name',
    filterKey: 'department__id',
    selectorsKey: selectorKeys.department,
    dynamicHyperlinks: data => {
      switch (data.type) {
        case BudgetTypes.TEAM_BUDGET:
          return pathToUrl(selectorToUrl[selectorKeys.team] as string, {
            id: String(data.entity_id),
          })

        case BudgetTypes.DEPARTMENT_BUDGET:
          return pathToUrl(selectorToUrl[selectorKeys.department] as string, {
            id: String(data.entity_id),
          })
      }
      if (data.is_requisition) {
        return pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, {
          id: String(data.entity_id),
        })
      }
      return pathToUrl(selectorToUrl[selectorKeys.employee] as string, {
        id: String(data.entity_id),
      })
    },
    colors: (data, theme) => getEntityNameColor(data.entity_name, theme),
    title: 'Name',
  }

export const budgetTeamNameColumn: ColumnInterface<BudgetInterface> = {
  type: CellTypes.text,
  idPoint: 'team_id',
  dataPoint: 'team_name',
  sortKey: 'team__name',
  filterKey: 'team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
}

export const budgetEmployeeNameColumn: ColumnInterface<BudgetInterface> = {
  type: CellTypes.text,
  idPoint: 'full_name',
  dataPoint: 'full_name',
  sortKey: 'full_name',
  filterKey: 'full_name',
  selectorsKey: selectorKeys.revoluters_all_names,
  colors: (data, theme) => getEntityNameColor(data.full_name, theme),
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.KPI.PERSONAL, { id: data.id }),
  title: 'Name',
}

export const companyBudgetTotalColumn: ColumnInterface<CompanyBudgetInterface> = {
  type: CellTypes.insert,
  idPoint: 'total_current_spent',
  dataPoint: 'total_current_spent',
  sortKey: 'total_current_spent',
  filterKey: 'total_current_spent',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.team,
  insert: ({ data }) => {
    if (typeof data.total_current_spent !== 'number') {
      return '-'
    }

    return formatMoney(data.total_current_spent, data.budget_currency)
  },
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide total/mo.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show total/mo.</TooltipContainer>,
  },
  textAlign: 'right',
  title: 'Salary/mo',
}

export const budgetAnnualExpenseColumn: ColumnInterface<BudgetInterface> = {
  type: CellTypes.insert,
  idPoint: 'annual_expense',
  dataPoint: 'annual_expense',
  sortKey: 'annual_expense',
  filterKey: 'annual_expense',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (typeof data.annual_expense !== 'number') {
      return '-'
    }

    return formatMoney(data.annual_expense, data.expense_currency)
  },
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide total/year</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show total/year</TooltipContainer>,
  },
  textAlign: 'right',
  title: 'Salary/year',
}

export const companyBudgetPerfReviewColumn: ColumnInterface<CompanyBudgetInterface> = {
  type: CellTypes.insert,
  idPoint: 'performance',
  dataPoint: 'performance',
  sortKey: 'performance',
  filterKey: 'performance',
  filterType: FilterType.percentRange,
  selectorsKey: selectorKeys.team,
  insert: ({ data, theme }) => {
    if (data.performance !== undefined && data.performance !== null) {
      return (
        <ColoredPercent
          percent={data.performance * 100}
          color={percentToColor(theme, data.performance * 100)}
        />
      )
    }

    return '-'
  },
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide performance.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show performance.</TooltipContainer>,
  },
  title: 'Perf Review',
}

const RightAligned = styled.div`
  margin-left: auto;
`

export const budgetPerfLabelReviewColumn: ColumnInterface<BudgetInterface> = {
  type: CellTypes.insert,
  idPoint: 'performance_label',
  dataPoint: 'performance_label',
  sortKey: 'performance_label',
  filterKey: 'performance_label',
  selectorsKey: selectorKeys.performance_score_labels,
  insert: ({ data, theme }) => {
    const isCurrentCycle = data.performance != null
    const label = isCurrentCycle ? data.performance_label : data.latest_performance_label

    if (!label) {
      return '-'
    }

    return (
      <PercentageWrapper>
        <Tooltip
          text="Final grade for this quarter is not available (grade shown is the latest grade for this employee)"
          placement="bottom"
          hide={isCurrentCycle}
        >
          <Colored
            color={
              getInverseColorsAndGrade(theme, label)?.color || Token.color.foreground
            }
          >
            {label.label}
            {isCurrentCycle || !data.latest_performance_cycle ? (
              ''
            ) : (
              <Text color="foreground"> ({data.latest_performance_cycle})</Text>
            )}
          </Colored>
        </Tooltip>
        <RightAligned color={getColor(theme, Color.GREY_20_OPAQUE_90)}>
          <EmployeePerformanceChart
            id={data.id}
            fetchKpi={getEmployeePerformanceGraph}
            vertical="right"
          >
            <Colored color={getColor(theme, Color.GREY_20_OPAQUE_90)}>
              <Icon type="Graph" size="small" />
            </Colored>
          </EmployeePerformanceChart>
        </RightAligned>
      </PercentageWrapper>
    )
  },
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide performance.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show performance.</TooltipContainer>,
  },
  title: 'Perf Review',
}

export const budgetFinalGradeReviewColumn: ColumnInterface<BudgetInterface> = {
  type: CellTypes.insert,
  idPoint: 'latest_calibrated_grade__display_grade',
  dataPoint: 'latest_calibrated_grade__display_grade',
  sortKey: 'latest_calibrated_grade__absolute_rating_score__value',
  filterKey: 'latest_calibrated_grade__display_grade',
  selectorsKey: selectorKeys.performance_score_labels,
  insert: ({ data, theme }) => {
    const { grade, color } =
      getInverseColorsAndGrade(theme, data.latest_calibrated_grade?.display_grade) || {}

    if (!grade) {
      return '-'
    }

    return (
      <PercentageWrapper>
        <Text color={color}>{grade}</Text>
        <RightAligned color={getColor(theme, Color.GREY_20_OPAQUE_90)}>
          <EmployeePerformanceChart
            id={data.id}
            fetchKpi={getEmployeePerformanceGraph}
            vertical="right"
          >
            <Colored color={getColor(theme, Color.GREY_20_OPAQUE_90)}>
              <Icon type="Graph" size="small" />
            </Colored>
          </EmployeePerformanceChart>
        </RightAligned>
      </PercentageWrapper>
    )
  },
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide performance.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show performance.</TooltipContainer>,
  },
  title: 'Final grade',
}

export const companyBudgetRoleColumn: ColumnInterface<CompanyBudgetInterface> = {
  type: CellTypes.insert,
  idPoint: 'specialisation.id',
  dataPoint: 'specialisation.name',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.specialisations,
  insert: ({ data }) => {
    if (data.type === BudgetTypes.EMPLOYEE_BUDGET) {
      if (data?.specialisation?.name) {
        return (
          <CustomLink
            to={getLocationDescriptor(
              pathToUrl(selectorToUrl[selectorKeys.specialisations] as string, {
                id: data.specialisation.id,
              }),
            )}
          >
            {data.specialisation.name}
          </CustomLink>
        )
      }

      return '-'
    }

    return ' '
  },
  title: 'Role (Specialisation)',
}

export const budgetRoleColumn: ColumnInterface<BudgetInterface> = {
  type: CellTypes.text,
  idPoint: 'specialisation_id',
  dataPoint: 'specialisation_name',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role (Specialisation)',
}

export const companyBudgetSeniorityColumn: ColumnInterface<CompanyBudgetInterface> = {
  type: CellTypes.insert,
  idPoint: 'seniority.name',
  dataPoint: 'seniority.name',
  sortKey: 'seniority__level',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  insert: ({ data }) => {
    if (data.type === BudgetTypes.EMPLOYEE_BUDGET) {
      return data?.seniority?.name || '-'
    }

    return ' '
  },
  title: 'Seniority',
}

export const budgetSeniorityColumn: ColumnInterface<BudgetInterface> = {
  type: CellTypes.text,
  sortKey: 'seniority__level',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  idPoint: 'seniority_id',
  dataPoint: 'seniority_name',
  title: 'Seniority',
}

export const budgetSeniorityAndSublevelColumn: ColumnInterface<BudgetInterface> = {
  type: CellTypes.insert,
  sortKey: 'seniority__level',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  idPoint: 'seniority_id',
  dataPoint: 'specialisation_seniority_sublevel_name',
  title: 'Seniority',
  insert: ({ data }) => {
    if (data.specialisation_seniority_sublevel_name) {
      return (
        <Flex>
          {data.seniority_name} ({data.specialisation_seniority_sublevel_name})
        </Flex>
      )
    }
    return data.seniority_name
  },
}

export const budgetLineManagerColumn: ColumnInterface<BudgetInterface> = {
  type: CellTypes.insert,
  idPoint: 'line_manager_id',
  dataPoint: 'line_manager_name',
  sortKey: 'line_manager__name',
  filterKey: 'line_manager__id',
  selectorsKey: selectorKeys.manager,
  title: 'Line manager',
  insert: ({ data }) => (
    <UserWithAvatar
      id={data.line_manager_id}
      name={data.line_manager_name}
      avatar={data.line_manager_avatar}
      status={data.line_manager_status}
    />
  ),
}

export const budgetFunctionalManagerColumn: ColumnInterface<BudgetInterface> = {
  type: CellTypes.insert,
  idPoint: 'quality_control_id',
  dataPoint: 'quality_control_name',
  sortKey: 'quality_control__name',
  filterKey: 'quality_control__id',
  selectorsKey: selectorKeys.manager,
  title: 'Functional Manager',
  insert: ({ data }) => (
    <UserWithAvatar
      id={data.quality_control_id}
      name={data.quality_control_name}
      avatar={data.quality_control_avatar}
      status={data.quality_control_status}
    />
  ),
}

export const companyBudgetOwnerColumn: ColumnInterface<CompanyBudgetInterface> = {
  type: CellTypes.text,
  idPoint: 'entity_owner_id',
  dataPoint: 'entity_owner',
  sortKey: 'entity_owner',
  filterKey: 'entity_owner_id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner / Manager',
}

export const budgetStartDateColumn: ColumnInterface<BudgetInterface> = {
  type: CellTypes.date,
  idPoint: 'joining_date_time',
  dataPoint: 'joining_date_time',
  sortKey: 'joining_date_time',
  filterKey: 'joining_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Start date',
}

export const companyBudgetLocationColumn: ColumnInterface<CompanyBudgetInterface> = {
  type: CellTypes.insert,
  idPoint: 'location.location_name',
  dataPoint: 'location.location_name',
  sortKey: 'location__location_name',
  filterKey: 'location__id',
  selectorsKey: selectorKeys.location,
  insert: ({ data }) => {
    if (data.type === BudgetTypes.EMPLOYEE_BUDGET) {
      return data?.location?.location_name || '-'
    }

    return ' '
  },
  title: 'Location',
}

export const budgetLocationColumn: ColumnInterface<BudgetInterface> = {
  type: CellTypes.text,
  idPoint: 'location_id',
  dataPoint: 'location_name',
  sortKey: 'location__name',
  filterKey: 'location__id',
  selectorsKey: selectorKeys.location,
  title: 'Location',
}

export const companyBudgetStatusColumn: ColumnInterface<CompanyBudgetInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.employee_status_pending,
  insert: ({ data }) => {
    if (data.type === BudgetTypes.EMPLOYEE_BUDGET) {
      return capitalize(data?.status)
    }

    return ' '
  },
  title: 'Status',
}

export const budgetStatusColumn: ColumnInterface<BudgetInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.employee_status_pending,
  insert: ({ data }) => capitalize(data?.status),
  title: 'Status',
}
