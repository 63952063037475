import {
  Badge,
  Box,
  FilterButton,
  Flex,
  Icon,
  Text,
  Token,
  Tooltip,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'
import {
  CardContentTypes,
  cardHasMissingValues,
  GradeOption,
  SelectedFieldInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, isEmpty, lowerCase } from 'lodash'
import {
  ReviewScorecardInterface,
  ReviewScorecardViewInterface,
  ReviewSummaryInterface,
  SkillCardInterface,
} from '@src/interfaces/performance'
import { DeliverableScorecardInterface } from '@src/interfaces/deliverables'
import React, { useEffect, useRef, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CardField } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import styled from 'styled-components'

const CardItemContainer = styled(Flex)`
  cursor: pointer;
  transition: all 0.3s;
  min-height: 80px;
  &:hover {
    background-color: ${Token.color.blue_5};
  }
`

const StyledBadge = styled(Badge)`
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  z-index: ${props => props.theme.zIndex.main};
  min-width: 13px;
  height: 13px;
  padding: 0;
  border: 2px solid ${Token.color.background};
  box-sizing: content-box;
`

interface CardItemProps {
  data:
    | ReviewScorecardInterface
    | ReviewSummaryInterface
    | ReviewScorecardViewInterface
    | DeliverableScorecardInterface
  renderExpandedContent: (selectedField: SelectedFieldInterface) => React.ReactNode
  renderExceedingContent?: (selectedField: SelectedFieldInterface) => React.ReactNode
  field: CardField
  isViewMode: boolean
  type: CardContentTypes
  cardItemIndex: number
  getFieldDetails: (
    field: CardField,
    grade: GradeOption,
  ) => { selected: boolean; aboveExpectation: boolean; differFromExpectation: boolean }
  onGradeClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    field: CardField,
    grade: GradeOption,
  ) => void
}

interface FilterButtonWithTooltipProps {
  grade: GradeOption
  aboveExpectation: boolean
  onGradeClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    field: CardField,
    grade: GradeOption,
  ) => void
  field: CardField
  selected: boolean
  isViewMode: boolean
  type: CardContentTypes
  cardItemIndex: number
}

const getLoweredType = (type: CardContentTypes | string) => {
  return lowerCase(type).replace(' ', '-')
}

const FilterButtonWithTooltip = ({
  grade,
  aboveExpectation,
  onGradeClick,
  field,
  selected,
  isViewMode,
  type,
  cardItemIndex,
}: FilterButtonWithTooltipProps) => {
  const tooltip = useTooltip()

  return (
    <Flex
      key={grade.key}
      justifyContent="center"
      style={{ position: 'relative' }}
      {...tooltip.getAnchorProps()}
    >
      {aboveExpectation && selected && (
        <StyledBadge
          useIcon="16/ExclamationMarkSign"
          backgroundColor={Token.color.orange}
        />
      )}
      <FilterButton
        onClick={e => onGradeClick(e, field, grade)}
        active={selected}
        style={{ height: '30px' }}
        px="s-12"
        disabled={isViewMode && !selected}
        data-testid={`card-${getLoweredType(type)}-${cardItemIndex}-${getLoweredType(
          grade.text,
        )}${selected ? '-active' : ''}`}
      >
        <Text data-testid="button-text">{grade.text}</Text>
      </FilterButton>
      {(grade.tooltip || aboveExpectation) && (
        <Tooltip {...tooltip.getTargetProps()} placement="top-end" maxWidth={300}>
          {aboveExpectation && <Text color={Token.color.orange}>Above expectations</Text>}
          {grade.tooltip}
        </Tooltip>
      )}
    </Flex>
  )
}

export const CardItem = ({
  data,
  field,
  isViewMode,
  type,
  cardItemIndex,
  getFieldDetails,
  onGradeClick,
  renderExpandedContent,
  renderExceedingContent,
}: CardItemProps) => {
  const { errors } = useLapeContext<ReviewScorecardInterface>()
  const [expanded, setExpanded] = useState(false)
  const cardItemRef = useRef<HTMLDivElement | null>(null)

  const handleClickOutside = (e: Event) => {
    if (!cardItemRef?.current?.contains(e.target as Node)) {
      setExpanded(false)
    }
  }

  useEffect(() => {
    if (expanded) {
      window.addEventListener('click', handleClickOutside)
    } else {
      window.removeEventListener('click', handleClickOutside)
    }

    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [expanded, cardItemRef.current])

  const fieldValue = get(data, field.field)
  const hasMissingValues = cardHasMissingValues(
    type,
    fieldValue as SkillCardInterface,
    errors,
  )
  const hasErrors = !isEmpty(get(errors, field.field))
  const validationFailed = hasErrors && hasMissingValues

  const { differFromExpectation } = getFieldDetails(field, {
    key: fieldValue?.recommended_rating || fieldValue?.rating || null,
    text: '',
  })

  return (
    <VStack ref={cardItemRef}>
      <CardItemContainer
        justifyContent="space-between"
        alignItems="center"
        my="s-2"
        p="s-20"
        bg={validationFailed ? Token.color.inputError : undefined}
        borderRadius={Token.radius.r12}
        onClick={() => {
          setExpanded(!expanded)
        }}
        data-testid={`card-item-${getLoweredType(type)}-${cardItemIndex}`}
      >
        <Text variant="h6" fontWeight={500} lineHeight={Token.lineHeight.caption}>
          {field.title}
        </Text>
        <Flex alignItems="center" justifyContent="flex-end">
          {!expanded && field.grades[0]?.text && (
            <Flex gap="s-4" alignItems="center">
              {field.grades.map(grade => {
                const { selected, aboveExpectation } = getFieldDetails(field, grade)
                return (
                  <FilterButtonWithTooltip
                    key={grade.key}
                    grade={grade}
                    aboveExpectation={aboveExpectation}
                    onGradeClick={onGradeClick}
                    field={field}
                    selected={selected}
                    isViewMode={isViewMode}
                    type={type}
                    cardItemIndex={cardItemIndex}
                  />
                )
              })}
            </Flex>
          )}
          <Box ml="s-8" data-testid="card-button">
            <Icon
              name={expanded ? 'ChevronDown' : 'ChevronRight'}
              size={20}
              color={Token.color.greyTone20}
            />
          </Box>
        </Flex>
      </CardItemContainer>
      {expanded &&
        renderExpandedContent({
          type,
          field,
          cardIndex: cardItemIndex,
        })}
      {!expanded &&
        differFromExpectation &&
        renderExceedingContent?.({
          type,
          field,
          cardIndex: cardItemIndex,
        })}
    </VStack>
  )
}
